import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  ItemConsultasFrecuentes,
  ItemUltimosRegistros,
  ListaPeticionesDisponibilidad
} from 'src/app/autorreserva/autorreserva-compartido/lista-peticiones-disponibilidad/Modelos';
import { SolicitudAeropuerto } from 'src/app/modelos/transfers/aeropuerto/apiAeropuerto';

@Injectable({ providedIn: 'root' })
export abstract class AutoReservaService {
  private _listaPeticionesDisponibilidad = new Subject<ListaPeticionesDisponibilidad>();
  listaPeticionesDisponibilidad$ = this._listaPeticionesDisponibilidad.asObservable();

  private _registroSeleccionado = new Subject<ItemUltimosRegistros>();
  registroSeleccionado$ = this._registroSeleccionado.asObservable();

  private _ubicacionFrecuenteSeleccionada = new Subject<ItemConsultasFrecuentes>();
  ubicacionFrecuenteSeleccionada$ = this._ubicacionFrecuenteSeleccionada.asObservable();

  private _residenteObligatorio = new BehaviorSubject<boolean>(false);
  residenteObligatorio$ = this._residenteObligatorio.asObservable();

  private _idEmpresaViajeros = new BehaviorSubject<number>(null);
  idEmpresaViajeros$ = this._idEmpresaViajeros.asObservable();

  constructor(protected http: HttpClient) { }

  GetGeocodeAPI(cadena, coordenadas, codigoISO, lenguaje): Observable<any> {
    if (environment.pruebas || environment.demo) {
      return this.http.get('assets/misc/respuestas_API/autoreserva/busqueda_lugar.json');
    } else {
      return this.http.get(
        `/api/Lugares?cadena=${cadena}&coordenadas=${coordenadas}&lang=${lenguaje}&codigo=${codigoISO}&dummy=1`
      );
    }
  }

  PostSeleccionarViajeros(peticion): Observable<any> {
    if (environment.pruebas || environment.demo) {
      return this.http.get('assets/misc/respuestas_API/autoreserva/seleccionar_viajeros.json');
    } else {
      if (!peticion.tipo) {
        peticion = peticion.map((viajero) => ({ guid: viajero.guid, tipo: 2 }));
      }
      return this.http.post(`/api/SeleccionarViajeros`, peticion);
    }
  }


  GetVerOpciones() {
    if (environment.demo) {
      return this.http.get('assets/misc/respuestas_API/autoreserva/opciones_viajeros_con_proyectos.json');
      // return this.http.get('assets/misc/respuestas_API/autoreserva/opciones_viajeros.json');
    } else {
      return this.http.get('/api/DatosReserva/VerOpciones');
    }
  }

  SetDatosReserva(datos) {
    if (environment.demo) {
      return of(undefined);
    } else {
      return this.http.post('/api/DatosReserva/Seleccionar', datos);
    }
  }

  finalizarReserva() {
    localStorage.removeItem('datosDashboardAdministracion');
    localStorage.removeItem('datosDashboardAutoreserva');
  }

  getListaPeticionesDisponibilidad(tipo: TipoProducto) {
    let url = `/api/ListarPeticionesDisponibilidad?tipoProducto=${tipo}`;
    if (environment.demo) {
      const tipoTexto = TipoProducto[tipo].toLowerCase();
      url = `assets/misc/respuestas_API/autoreserva/${tipoTexto}/lista_peticiones_disponibilidad.json`;
    }
    return this.http.get(url) as Observable<ListaPeticionesDisponibilidad>;
  }

  setListaPeticionesDisponibilidad(lista: ListaPeticionesDisponibilidad) {
    this._listaPeticionesDisponibilidad.next({ ...lista });
  }

  seleccionarUbicacionFrecuente(item: ItemConsultasFrecuentes) {
    this._ubicacionFrecuenteSeleccionada.next({ ...item });
  }

  seleccionarRegistro(item: ItemUltimosRegistros) {
    this._registroSeleccionado.next({ ...item });
  }

  setResidenteObligatorio(value: boolean) {
    this._residenteObligatorio.next(value);
  }

  getPaises() {
    return this.http.get('assets/misc/paises.json');
  }

  getReferenciasEspecificas() {
    if(environment.demo){
      return this.http.get('assets/misc/respuestas_API/autoreserva/correosreferencias/correosreferencias.json');
    } else{
      return this.http.get('/api/CorreosReferencias');
    }
  }
  montarReferencia(tipoProyecto: any, referencia: any, refacturar: any): string{
    refacturar = JSON.parse(refacturar.toLowerCase());

    let campoReferencia = "";

    if (tipoProyecto == "Interno") {
      campoReferencia = referencia.ID;
    } else{
      campoReferencia =  `${((refacturar == true) ? "Facturable " : "")}${referencia.ID}`
    }

    return campoReferencia;
  }

  crearProyecto(datosProyecto: { Codigo: string; Descripcion: string; }) {
    if(environment.demo){
      return of("999");
    } else{
      return this.http.post('/api/DatosReserva/NuevoProyecto', datosProyecto);
    }
  }

  setEmpresaViajeros(idEmpresa: any):void {
    this._idEmpresaViajeros.next(idEmpresa);
  }

  getEmpresaViajeros():number {
    return this._idEmpresaViajeros.getValue();
  }
}

export enum TipoProducto {
  Hotel = 1,
  Aereo = 2,
  RentAcar = 3,
  Barco = 4,
  Tren = 5,
  Transfer = 9
}
